<template>
  <el-card>
    <el-row justify="end" style="margin-bottom: 30px">
      <el-label style="font-size: 16px">
        已有账号？请
        <router-link :to="{name:'login' }">登陆</router-link>
      </el-label>
    </el-row>
    <el-row justify="center" style="margin-bottom: 20px; font-size: 25px">
      <el-label>注册新用户</el-label>
    </el-row>
    <el-row justify="center">
      <el-form
        label-width="0px"
        :model="params"
        ref="registerForm"
        :rules="rules"
        v-loading="loading"
      >
        <el-form-item prop="account">
          <el-input placeholder="用户名" v-model="params.account"></el-input>
        </el-form-item>
        <el-form-item prop="department">
          <el-input placeholder="部门" v-model="params.department"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="密码"
            v-model="params.password"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            placeholder="确认密码"
            v-model="params.confirmPassword"
            type="password"
          ></el-input>
        </el-form-item>
        <el-row justify="center">
          <el-button @click="register">提交</el-button>
        </el-row>
      </el-form>
    </el-row>
  </el-card>
</template>

<script>
import { userRegister } from "../api/user";
import { messageError, messageSuccess } from "../utils/message";
import { deepCopy } from "../utils/util";
const initParams = {
  account: "",
  department: "",
  password: "",
  confirmPassword: "",
};

export default {
  data() {
    const validPwdOnce = (rule, value, callback) => {
      if (value != this.params.confirmPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    const validPwdTwice = (rule, value, callback) => {
      if (value != this.params.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      params: deepCopy(initParams),
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        department: [
          { required: true, message: "请输入你所在部门", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: validPwdOnce,
            trigger: "change",
          },
        ],
        confirmPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: validPwdTwice,
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    register() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          userRegister(this.params)
            .then((resp) => {
              this.loading = false;
              if (resp.status == 201) {
                messageError("该用户已存在,请重新输入账号！");
              } else {
                  this.params = deepCopy(initParams);
                messageSuccess(
                  "注册成功，请联系管理员审核，审核成功后才能登陆！"
                );
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>


<style scoped>
.el-card {
  position: absolute;
  width: 650px;
  height: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 45%;
}
.el-form-item {
  width: 300px;
}
</style>